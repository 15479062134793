import {mapGetters} from "vuex";

export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
        itemValue: {
            type: String,
            default: 'default'
        },
        itemTitle: {
            type: String,
            default: 'name'
        },
        name: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: () => {
            }
        },
        group: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openSelect: false,
            selectedItem: this.value,
            collectSlug: [],
            selectedFilter: [],
            staticFilters: {},
            progress: false
        }
    },
    computed: {
        ...mapGetters({
            pageContent: 'catalog/currentCategory'
        })
    },
    watch: {},
    created() {

    },
    beforeMount() {
    },
    mounted() {

        this.staticFilters.name = this.pageContent.name
        this.staticFilters.id = this.pageContent.id
        this.staticFilters.id !== 1 ? this.openSelect = true : this.openSelect = false
    },
    methods: {
        toggleSelect() {
            !this.openSelect ? this.openSelect = true : this.openSelect = false
        }
    }
}
