import {mapMutations, mapActions, mapGetters} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
//sections
import filters from "@/modules/filter-products/section/filters/index.vue";
import products from "@/modules/filter-products/section/products/index.vue";
import {dynamicHead} from "../../mixins/dynamic-head";

export default {
    name: "main-filter",
    components: {
        filters,
        products
    },
    directives: {
        onClickaway: onClickaway,
    },
    data() {
        return {
            isOpenFilters: false,
            selectedAttributes: [],
            params: {
                title: ''
            }
        }
    },
    mixins: [dynamicHead],
    watch: {
        '$route.query': function () {
            this.selectedAttributes = []
            this.getAttributes()
        },
        'productFilters': function () {
            this.getAttributes()

        }
    },

    created() {
        this.getPageContent('categories').then(() => {
            this.pageInfo = this.pageContent
            this.setPageItem(this.pageContent)
        })
        this.getOneCategory(this.$route.params.slug).then(() => {
            this.setParams()
            this.setMeta()
        })
        this.getCurrentCategory(this.$route.params.slug).then(() => {
            this.setParams()
            this.setMeta()
        })
        this.setRequestFlag(true);
    },
    mounted() {
        let urlQuery = cloneDeep(this.$route.query);
    },
    computed: {
        ...mapGetters({
            globalRequestsFlag: 'system/globalRequestsFlag',
            pageContent: 'catalog/currentCategory',
            productFilters: 'catalog/productFilters',
        })
    },

    methods: {
        ...mapActions({
            getCurrentCategory: 'catalog/GET_ONE_CATEGORY',
            getPageContent: 'setting/GET_PAGE_CONTENT',
            getOneCategory: 'catalog/GET_CURRENT_CATEGORY'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        setParams() {
            this.params.title = this.pageContent.name
        },
        openFilters() {
            document.getElementById('html').classList.toggle('hide')
            document.getElementById('html-overlay').classList.toggle('html-overlay')
            this.isOpenFilters = !this.isOpenFilters

        },
        away: function () {
            document.getElementById('html').classList.remove('hide')
            document.getElementById('html-overlay').classList.remove('html-overlay')
            this.isOpenFilters = false
        },
        getAttributes() {

            if (Object.values(this.$route.query).length) {
                let values = []
                for (let key in this.$route.query) {
                    let obj = {}
                    obj.id = key
                    obj.value = this.$route.query[key]
                    values.push(obj)
                }

                this.productFilters.filters.forEach(el => {
                    el.attribute.options.data.forEach(attr => {
                        values.forEach(val => {
                            let attributes = val.value.split(',')
                            attributes.forEach(a => {
                                if (parseInt(a) === attr.id) {
                                    this.selectedAttributes.push({id: attr.id, title: attr.title, parentId: val.id})
                                }
                            })
                        })
                    })
                })
                let uniqFilters = uniqBy(this.selectedAttributes, 'id', 'title')
                this.selectedAttributes = uniqFilters
                return this.selectedAttributes
            }
        },
        deleteAttribute(item, index) {
            let key = item.parentId;
            let val = item.id.toString();
            let urlQuery = cloneDeep(this.$route.query);

            if (urlQuery[key]) {
                let arrValues = urlQuery[key].toString().split(',');

                let indexVal = arrValues.findIndex((item) => {
                    return item === val
                })
                if (indexVal > -1) {
                    arrValues.splice(indexVal, 1)
                    if (arrValues.length) {
                        urlQuery[key] = arrValues.join(',');
                    } else {
                        delete urlQuery[key];
                    }
                } else {
                    urlQuery[key] = urlQuery[key] + ',' + val;
                }
            } else {
                urlQuery[key] = val;
            }

            this.$router.replace({
                name: 'category',
                params: this.$route.params,
                query: urlQuery
            })
            let idx = this.selectedAttributes.findIndex(el => el.id === item.id)
            this.selectedAttributes.splice(idx, 1)
        }
    }
}
