import {directive as onClickaway} from 'vue-clickaway';
import Vue from "vue";

export default {
    directives: {
        onClickaway: onClickaway,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        itemValue: {
            type: String,
            default: 'default'
        },
        name: {
            type: String,
            default: ''
        },
        itemTitle: {
            type: String,
            default: 'name'
        },
        item: {
            type: Object,
            default: () => {
            }
        },
        group: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openSelect: false,
            selectedItem: this.value,
            collectSlug: [],
            selectedFilter: [],
            progress: false,
            filters: []
        }
    },
    watch: {
        '$route.query': function () {
            this.filters = []
            for (let key in this.$route.query) {
                this.$route.query[key].split(',').forEach(str => {
                    this.filters.push(str)
                })

            }
        },
        value() {
            this.changeSelect(this.value)
        }
    },
    mounted() {
        for (let key in this.$route.query) {
            this.$route.query[key].split(',').forEach(str => {
                this.filters.push(str)
            })

        }
    },
    created() {
        this.selectedItem = this.value;
        this.item.id !== 1 ? this.openSelect = true : this.openSelect = false
    },
    methods: {
        changeEvent(item) {
            this.$emit('change-event', item)
        },
        getSelectedAttributes(item) {
            return this.filters.find(filter => parseInt(filter) === item.id)
        },
        changeSelect(item) {
            this.selectedItem = item;
            this.$emit('input', item);
        },
        away: function () {
            this.openSelect = false
        },
        toggleSelect() {
            !this.openSelect ? this.openSelect = true : this.openSelect = false
        }
    }
}
